import React from 'react'
import Product01 from './images/product01'
import Product02 from './images/product02'
import Product03 from './images/product03'
import Product04 from './images/product04'
import Product05 from './images/product05'
import Product06 from './images/product06'

export default function ProductCard({id, name, catchphrase, contents, url}) {
  function ProductImage({id}) {
    if (id === 'product-01') {
      return <Product01 />
    }
    if (id === 'product-02') {
      return <Product02 />
    }
    if (id === 'product-03') {
      return <Product03 />
    }
    if (id === 'product-04') {
      return <Product04 />
    }
    if (id === 'product-05') {
      return <Product05 />
    }
    if (id === 'product-06') {
      return <Product06 />
    }
  }

  return (
    <div>
      <ProductImage id={id} />
      <h2 className="mt-6 font-medium text-xl md:text-2xl">{name}</h2>
      <h3 className="mt-2 text-xl">{catchphrase}</h3>
      <p
        dangerouslySetInnerHTML={{__html: contents}}
        className="pt-4 leading-7"
      />
    </div>
  )
}
