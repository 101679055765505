import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function Product02() {
  return (
    <StaticImage
      src="../../images/product-02.jpg"
      alt=""
      layout="fullWidth"
      placeholder="blurred"
      formats={[ 'auto', 'webp', 'avif' ]}
      className="rounded-xl shadow-md"
    />
  )
}
