import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Heading from '../components/heading'
import ProductCard from '../components/product'
import SEO from '../components/seo'

export default function Product() {
  const title = {
    jp: '商品案内',
    en: 'PRODUCT'
  }

  const catchphrase = {
    title: 'サンリクの海藻は日本国内産にこだわっています。',
    contents:
      '社長自らが海岸に足を運び、漁師たちの声を聞き、その味や香りを確かめ、仕入れるものを決めています。<br />' +
      'また地元の人たちと長年築き上げてきた信頼関係により、<br />' +
      'おいしい海藻の情報を得て、食卓へ特別なおいしさを届けています。'
  }

  const heading = '商品案内'

  const products = [
    {
      id: 'product-01',
      name: '養殖わかめ：岩手県産',
      catchphrase: '香り高く歯ごたえありの人気商品',
      contents:
        '水温が低く荒い波にもまれた岩手県産のわかめは、<br />' +
        'その厳しい環境の中で育つゆえ、身が締まり肉厚で<br />' +
        '香りが高く、ほどよい歯ごたえが魅力です。<br />' +
        'そのシャキシャキとした食感と食べやすさから、幅広い年代に好まれ、最も人気が高い商品となっています。',
      url: '#'
    },
    {
      id: 'product-02',
      name: '養殖わかめ：徳島県産',
      catchphrase: '柔らかでコシのある食感は食べやすさ◎',
      contents:
        '早いうず潮の海流で育った徳島産のわかめは、流れにちぎれてしまわぬよう、薄くしなやかでコシのあることと、葉が薄い分、鮮やかで透明感のある美しい緑色を持つ点が特徴。<br />' +
        'その柔らかな弾力の歯ごたえと、風味の良さは、年配の方や小さなお子さまにもおすすめです。',
      url: '#'
    },
    {
      id: 'product-03',
      name: '天然わかめ：徳島県産',
      catchphrase: '固めの歯ごたえ希少な国産天然わかめ',
      contents:
        '強い波にもまれて育った天然わかめは、なんと言ってもそのゴリゴリとした肉厚な歯ごたえが最大の魅力。<br />' +
        'お刺身でもお味噌汁でもおいしくいただけます。<br />' +
        '養殖に比べ、圧倒的にとれる量が少ない天然ものは、市場に出回りづらく、大変貴重なものとなっています。',
      url: '#'
    },
    {
      id: 'product-04',
      name: '昆布：北海道産',
      catchphrase: '甘みのある上質な「だし」が取れる',
      contents:
        '肉質や柔らかく、煮物の具材や昆布巻に適しているとされる日高昆布ですが、<br />' +
        '日本人好みの甘みがある「だし」を取ることも出来るので、「だし昆布」としても人気が高く、煮物に使うと煮含みが良く、磯の旨み溢れる仕上がりになります。',
      url: '#'
    },
    {
      id: 'product-05',
      name: 'もずく：沖縄県産',
      catchphrase: 'おいしさを引き立てるタレにもこだわる',
      contents:
        'もずく本来の食感を損なわないようにするため、<br />' +
        '当社の製品は、漁から加工、パッキングまで全て手作業で行っています。添付のタレも、試食を重ね、一番もずくとの相性がよかったものを特注。お客さまから「タレもすごくおいしい」と評判を得ています。',
      url: '#'
    },
    {
      id: 'product-06',
      name: 'ひじき：国内産',
      catchphrase: '国産ならではの歯ごたえと香りを楽しむ',
      contents:
        '外国産とは比べものにならない、絶妙な味わいとしっかりとした歯ごたえがあり、身がしまっているという特徴を持つ国産ひじき。<br />' +
        '煮物にすると、ふっくらと柔らかく仕上がります。<br />' +
        '乾燥タイプは贈答品として大変喜ばれています。',
      url: '#'
    },
  ]

  return (
    <Layout>
      <SEO title="商品案内 | 株式会社サンリク" />
      <PageTitle titleJP={title.jp} titleEN={title.en} />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        <Heading heading={heading} />

        <h2 className="mt-10 md:mt-20 md:text-center font-bold text-2xl md:text-3xl">{catchphrase.title}</h2>
        <p
          dangerouslySetInnerHTML={{ __html: catchphrase.contents }}
          className="md:text-center mt-10 text-lg leading-8"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-10 md:gap-y-20 mt-20">
          {products.map(product => (
            <ProductCard
              key={product.id}
              id={product.id}
              name={product.name}
              catchphrase={product.catchphrase}
              contents={product.contents}
              url={product.url}
            />
          ))}
        </div>
      </div>

    </Layout>
  )
}
